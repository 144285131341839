$(document).ready(function() {

    var forms = document.getElementsByTagName('form');
    for (var i = 0; i < forms.length; i++) {
      var form = forms[i];
  
      if (form) {
        form.addEventListener("freeform-ready", function (event) {
          var freeform = event.target.freeform;
  
          freeform.setOption("errorClassBanner", ["alert","alert-danger"]);
          freeform.setOption("errorClassList", ["text-red-400", "text-sm", "uppercase"]);
          freeform.setOption("errorClassField", ["border-red-400"]);
          freeform.setOption("successClassBanner", ["alert", "alert-info"]);
        })
  
        // Override the way form submit success message is displayed
        form.addEventListener('freeform-render-success', function(event) {
          // Prevent the default behaviour
          event.preventDefault();
          var freeform = event.target;
  
          var successMessage = document.createElement("div");
          successMessage.setAttribute('id', 'success-block');
          successMessage.classList.add("ff-form-success","alert", "alert-info", "form-success");
  
          var paragraph = document.createElement("p");
          paragraph.innerHTML = "<i class='fas fa-check'></i>&nbsp;&nbsp;Thank you! Form sent successfully";
          successMessage.appendChild(paragraph);
  
          freeform.appendChild(successMessage);
        });
  
        // Override the way form errors are displayed
        form.addEventListener('freeform-render-form-errors', function(event) {
          // Prevent the default behaviour
          event.preventDefault();
          var freeform = event.target;
  
          var errors = event.errors;
  
          var errorBlock = document.createElement("div");
          errorBlock.setAttribute('id', 'error-block');
          errorBlock.classList.add("ff-form-errors","alert","alert-danger");
  
          var paragraph = document.createElement("p");
          paragraph.style.marginTop = '0';
          paragraph.innerHTML = "<i class='fas fa-times'></i>&nbsp;&nbsp;Please fill out all mandatory fields";
          errorBlock.appendChild(paragraph);
  
          if (errors.length) {
            var errorsList = document.createElement("ul");
            for (let messageIndex = 0; messageIndex < errors.length; messageIndex++) {
              var message = errors[messageIndex];
              var listItem = document.createElement("li");
  
              listItem.appendChild(document.createTextNode(message));
              errorsList.appendChild(listItem);
            }
  
            errorBlock.appendChild(errorsList);
          }
  
          freeform.appendChild(errorBlock);
        });
  
        // form.addEventListener("freeform-on-submit", function (event) {
        //   var freeform = event.target;
        //   debugger;
        //   successBlock = freeform.getElementById('success-block');
        //   if(successBlock) successBlock.parentNode.removeChild(successBlock)
        //   errorBlock = freeform.getElementById('error-block');
        //   if(errorBlock) errorBlock.parentNode.removeChild(errorBlock)
        // });
  
        // Override the way field errors are displayed
        // form.addEventListener('freeform-render-field-errors', function(event) {
        //   // Prevent the default behaviour
        //   event.preventDefault();
        //   var freeform = event.target;
          
        //   debugger;
  
        //   var errors = event.errors;
        //   for (var key in errors) {
        //     if (!errors.hasOwnProperty(key) || !key) {
        //       continue;
        //     }
  
        //     var inputList = freeform.querySelectorAll("*[name=" + key + "], *[name='" + key + "[]']");
        //     for (let inputIndex = 0; inputIndex < inputList.length; inputIndex++) {
        //       var input = inputList[inputIndex];
        //       input.classList.add("border-red-400");
        //     }
        //   }
        // });
  
        form.addEventListener("freeform-ajax-after-submit", function (event) {
          var message = { height: document.body.scrollHeight + 20};
          // window.top refers to parent window
          window.top.postMessage(message, "*");
        });
      }
    }
  
  });  